<template>
  <div>
    <h1 class="display-1 font-weight-bold mx-1 mb-3">
      Small Mounted Prints
    </h1>
    <h2>TBD</h2>
    <v-img src="../../../assets/Background-Small-Mounted-Prints-2366x901-.jpeg" />
  </div>
</template>

<script>
export default {
  props: {
  },
  components: {
  },
  data () {
    return {
    }
  },
  async created () {
  },
  computed: {
  },
  watch: {},
  methods: {
  }
}
</script>
